    <template>
    <div class="request-author">
        <Popup
            class="popup"
            custom-class="gray-header"
            ref="popupExampleRef"
            modal-id="add-author"
            :on-close="onModalClose"
            size="lg"
            :toggle-status="toggleStatus"
        >
            <template v-slot:header>
                {{ labels.manage_authors }}
            </template>
            <template v-slot:body>
                <div class="row h-100">
                    <div class="request-author__list h-100 mb-4 mb-md-0 col-12 col-md-6">
                        <TableOverview
                            kind="user"
                            :settings="usersList"
                            :entities="selectedOwners"
                        />
                    </div>
                    <div class="request-author__table h-100 col-12 col-md-6">
                        <TableOverview
                            kind="user"
                            :settings="usersToAdd"
                            :entities="allOwners"
                        />
                    </div>
                </div>
            </template>
        </Popup>
    </div>
</template>

<script>
    import TableOverview from "@/views/Dashboard/Common/TableOverview/";
    import Popup from "@/components/Popups/Popup";
    const colors = require("/colors.config.json");
    import { mapMutations, mapActions, mapState, mapGetters } from "vuex";

    export default {
        name: "CreateModule",
        components: {
            Popup,
            TableOverview
        },
        data() {
            return {
                toggleStatus: true,
                allOwners: [],
                updateKey: Date.now()
            };
        },
        computed: {
            ...mapState("space", ["singleSpace"]),
            ...mapGetters("helper", ["labels"]),
            usersToAdd() {
                return {
                    header: [
                        {
                            headerLabel: this.labels.available_admins,
                            headerColor: `${colors.gray_text}`,
                            key: "username",
                            computeFn: (input) => input,
                            bodyColor: `${colors.dark}`,
                            columnWidth: "1 1 60%",
                            whiteSpace: "nowrap"
                        }
                    ],
                    sortable: false,
                    minTableWidth: "100%",
                    actionsWidth: "0 0 40%",
                    actionsHeaderLabel: "",
                    actions: [
                        {
                            label: this.labels.add,
                            variant: "btn-purple",
                            styles: { padding: "8px 15px", fontWeight: "700" },
                            action: (e) => this.toggleUserInModule(e),
                            dynamicIdentifier: "_id",
                            labelFn: this.actionLabel
                        }
                    ]
                };
            },
            selectedOwners() {
                return this.singleSpace.owners || [];
            },
            usersList() {
                return {
                    header: [
                        {
                            headerLabel: this.labels.current_owners,
                            headerColor: `${colors.gray_text}`,
                            key: "username",
                            computeFn: (input) => input,
                            bodyColor: `${colors.dark}`,
                            columnWidth: "1 1 100%",
                            whiteSpace: "nowrap"
                        }
                    ],
                    sortable: false,
                    minTableWidth: "100%",
                    actionsHeaderLabel: "",
                    actions: [
                        {
                            label: this.labels.remove,
                            variant: "btn-purple",
                            styles: { padding: "8px 15px", fontWeight: "700" },
                            action: (e) => this.toggleUserInModule(e),
                            dynamicIdentifier: "_id"
                        }
                    ]
                };
            }
        },
        methods: {
            onModalClose() {
                this.$router.push({ name: "space-owners", params: { id: this.$route.params.id } });
            },
            ...mapMutations("helper", ["setTablePagination"]),
            ...mapActions("notification", ["addNotification"]),
            ...mapActions("user", ["getUsers"]),
            ...mapActions("space", ["removeOwner","addOwner"]),
            ...mapMutations("space", ["updateSingleSpaceData"]),
            isAuthor(id) {
                if (
                    this.selectedOwners?.length &&
                    this.selectedOwners.some((e) => e._id == id)
                ) {
                    return true;
                }

                return false;
            },
            actionLabel(id) {
                if (this.isAuthor(id)) {
                    return this.labels.remove_author;
                }

                return this.labels.add;
            },
            toggleUserInModule(user) {
                if (this.isAuthor(user._id)) {
                    console.log("Removing owner:", user);

                    this.removeOwner({
                        id: this.singleSpace._id,
                        user: user._id,
                    }).then((res) => {
                        if (res.success) {
                            let updatedModule = { ...this.singleSpace };

                            const indexOfAuthor = updatedModule.owners.findIndex(
                            (p) => p._id == user._id
                            );

                            updatedModule.owners.splice(indexOfAuthor, 1);

                            this.updateSingleSpaceData(updatedModule);

                            this.allOwners.push({
                                ...user
                            });

                            this.addNotification({
                                variant: "success",
                                msg: ["owner_removed"],
                                labels: this.labels,
                                dismissSecs: 5
                            });
                        } else if (res.error) {
                            this.addNotification({
                                variant: "danger",
                                msg: res.error,
                                labels: this.labels
                            });
                        }

                        return res;
                    }).catch((err) => console.log(err));

                    return;
                } else {
                    console.log("Adding author:", user);

                    this.addOwner({
                        id: this.singleSpace._id,
                        user: user._id
                    })
                    .then((res) => {
                        if (res.success) {
                            let updatedModule = { ...this.singleSpace };

                            if (!updatedModule.owners) updatedModule.owners = [];

                            updatedModule.owners.push({
                                _id: user._id,
                                username: user.username,
                            });

                            const indexOfAvailableAuthor = this.allOwners.findIndex(
                                (p) => p._id == user._id
                            );

                            this.allOwners.splice(indexOfAvailableAuthor, 1);

                            this.updateSingleSpaceData(updatedModule);

                            this.addNotification({
                                variant: "success",
                                msg: ["owner_added"],
                                labels: this.labels,
                                dismissSecs: 5
                            });
                        } else if (res.error) {
                            this.addNotification({
                                variant: "danger",
                                msg: res.error,
                                labels: this.labels
                            });
                        }
                            
                        return res;
                    }).catch((err) => console.log(err));

                    return;
                }
            },
            getUserList(page) {
                let idsToExclude = [];

                if (this.selectedOwners?.length) {
                    this.selectedOwners.forEach((author) => {
                        idsToExclude.push(author._id);
                    });
                }

                let data = {
                    page,
                    adminRole: "author",
                    excludeId: idsToExclude.join(),
                };

                return this.getUsers(data).then((res) => {
                    if (res.success) {
                        res.data.data.forEach((element) => {
                            this.allOwners.push({
                                username: element.username,
                                _id: element._id
                            });
                        });
                    } else if (res.error) {
                        this.addNotification({ variant: "danger", msg: res.error });
                    }

                    return res;
                }).catch((err) => console.log(err));
            }
        },
        mounted() {
            this.getUserList(1);
        }
    };
</script>

<style lang="scss" scoped>
    .request-author {
        &__list {
            border-right: 1px solid $light_gray;
        }

        &__list,
        &__table {
            overflow-y: auto;
        }
    }

    ::v-deep {
        .modal-body {
            @media (min-width: 768px) {
                overflow-y: hidden;
                height: 600px;
            }
        }

        .formulate-input {
            &[data-classification="textarea"] {
                flex: 1;

                & textarea {
                    height: 100%;
                }
            }

            &-wrapper,
            &-element {
                height: 100%;
            }
        }

        .overview {
            height: 100%;

            &__header {
                margin: 0;
            }
        }

        .base-card {
            &:not(.none) {
                box-shadow: unset;
                height: 100%;

                &:hover {
                    box-shadow: unset;
                }
            }
            .table-card__thead {
                padding-top: 0;
            }
            .table-card-row.last {
                border-bottom: none;
                padding-bottom: 0;
            }
        }

        .modal-footer {
            display: none;
        }

        .table-card {
            &__tbody {
                height: calc(100% - 40px);
                overflow-y: auto;
            }
        }
    }

    .description-title {
        font-family: "Oswald", sans-serif;
        line-height: 1.5;
        font-size: 0.9em;
        font-weight: 600;
        margin-bottom: 10px;
    }
</style>
